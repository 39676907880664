.popper-container-multiselect {
    z-index: 100 !important;
    margin-top: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    width: 500px !important;
    border-radius: 8px !important;
}

.menu-paper {
    padding: 24px;
    border-radius: 8px !important;
}

.form-control-label-align {
    align-items: flex-start !important;
    margin-top: 10px !important;
    word-break: break-all !important;
}

.form-control-label-align .MuiFormControlLabel-label {
    line-height: 25px !important;
}

.category-checkbox {
    padding: 0 9px !important;
}

.checkbox-options-container {
    max-height: 250px !important;
    overflow-y: auto !important;
}

.apply-filter-button, .clear-filter-button {
    float: right;
    margin-left: 10px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}


.popper-container-range {
    z-index: 100 !important;
    margin-top: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    width: auto !important;
    border-radius: 8px !important;
}

.menu-paper {
    padding: 24px;
    border-radius: 8px !important;
}

.MuiSlider-root,
.MuiSlider-rail,
.MuiSlider-track {
    height: 8px !important;
    border-radius: 5px !important;
}

.date-range-filter {
    margin-top: 10px !important;
}

.date-range-filter div[class^='materialui-daterange-picker-MuiPaper-root'] {
    box-shadow: none !important;
}

.MuiSlider-root {
    margin-top: 50px !important;
}

.date-range-container {
    height: auto;
}

.date-range-container .MuiPaper-root {
    box-shadow: none !important;
    border-top: 1px #f9f9fa solid;
    border-bottom: 1px #f9f9fa solid;
}

.date-range-container .materialui-daterange-picker-makeStyles-header-4 {
    display: none !important;
}


.date-range-filter ul {
    display: none !important;
}


.popper-container-rediobutton {
    z-index: 100 !important;
    margin-top: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    width: 500px !important;
    border-radius: 8px !important;
}

.menu-paper {
    padding: 24px;
    border-radius: 8px !important;
}

.radio-options-container {
    max-height: 250px !important;
    overflow-y: auto !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.radio-options-container .radio-button-group {
    display: contents !important;
}

.apply-filter-button, .clear-filter-button {
    float: right;
    margin-left: 10px !important;
}


.popper-container-number-range {
    z-index: 100 !important;
    margin-top: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    width: 500px !important;
    border-radius: 8px !important;
}

.menu-paper {
    padding: 24px;
    border-radius: 8px !important;
    /* border: solid 0.5px #283745; */
}

.MuiSlider-root,
.MuiSlider-rail,
.MuiSlider-track {
    height: 8px !important;
    border-radius: 5px !important;
}

.MuiSlider-root {
    margin-top: 50px !important;
}

.range-container {
    height: 100px;
}
