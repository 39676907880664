.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.displayEllipsis{
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.flexFull {
  flex: 1 !important;
  padding-top: 0px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Custom CSS */
.align-right{
  margin-left: auto !important;
}

.margin-left-5{
  margin-left: 5px !important;
}

.margin-top-10{
  margin-left: 10px !important;
}

.margin-top-5{
  margin-top: 5px !important;
}

.margin-left-0{
  margin-left: 0px !important;
}

.logo-image{
  height: 50px;
  width: 50px;
  margin: auto !important;
}

.MuiFormLabel-root{
  background-color: #fff !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.formFooter{
  margin-top: 10px !important;
  position: fixed;
  bottom: 0;
  /* box-shadow: 0px 0 10px #f5f5f5; */
  background-color: #fff;
  padding: 12px;
  width: calc(100% - 300px) !important;
  z-index: 11111 !important;

  border-radius: 10px 10px 0px 0px;
  box-shadow: inset 0 10px 10px -10px #d1cece;
}

.formFooterDialog{
  margin-top: 10px !important;
  position: fixed;
  bottom: 0;
  /* box-shadow: 0px 0 10px #f5f5f5; */
  background-color: #fff;
  padding: 12px;
  width: calc(100% - 70px) !important;
  z-index: 11111 !important;

  border-radius: 10px 10px 0px 0px;
  box-shadow: inset 0 10px 10px -10px #d1cece;
}

textarea{
  -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
}

table thead th{
  font-weight: bold !important;
}

.MuiAutocomplete-popper{
  z-index: 11111111 !important;
}

.MuiAutocomplete-popper .MuiPaper-root{
  max-height: 150px !important;
}

.MuiDataGrid-columnHeaderTitle{
  font-weight: bold !important;
  font-size: 15px !important;
}

.MuiFormHelperText-root.Mui-error{
  margin-top: 0px !important;
  margin-left: 14px !important;
  font-size: 14px !important;
  line-height: 1.44 !important;
  font-weight: 500 !important;
  letter-spacing: 0.033em !important;
}

.MuiAutocomplete-listbox{
  max-height: none !important;
}